.main__block__style {
    display: grid;
}

.first__block__style {
    display: flex;
    justify-content: space-evenly;
    /* margin-bottom: 1vw; */
    width: 60vw;
}

.second__block__style {
    display: flex;
    justify-content: space-evenly;
}

.second__block__style span {
    font-size: 0.85vw;
    font-family: "Roboto";
    font-weight: 500;
}

.second__block__style h3 {
    font-size: 0.9vw;
    font-family: "Roboto";
    font-weight: 500;
}

.description__block__style {
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* border: 2px red solid; */
}

.description__for__team {
    border-radius: 1vw;
    padding: 0.5vw 1vw;
    box-shadow: 0px 0px 0.5vw 0px #0000001A;
    border: solid 0.2vw var(--main-color-border);
    background-color: white;
    width: 80%;
    max-height: 13vw;
    overflow: auto;
    /* border: 2px red solid; */
}

.list__participants__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: violet 2px solid; */
    justify-content: start;
    width: 25vw;
    margin-right: 3vw;
    /* border: red 2px solid; */
}

.list__participants__style h3 {
    display: flex;
    justify-content: center;
}

.list__style {
    box-shadow: 0px 0px 0.5vw 0px #0000001A;
    border: solid 0.2vw var(--main-color-border);
    display: grid;
    gap: 1vw 0vw;
    padding: 0.7vw;
    border-radius: 1vw;
    background-color: white;
    width: 80%;
    /* border: red 2px solid; */
}

.description__span__style {
    font-size: 0.85vw;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 1.5vw !important;
}

.qr__style span {
    font-size: 1vw;
    font-family: "Roboto";
    font-weight: 500;
    margin-bottom: 1vw;
}

.qr__style {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vw;
}

.presentation__wrapper{
    width: 60vw;
}

.git__user__style {
    /* border: 2px red solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    box-sizing: border-box;
    gap: 15px;
}

.presentation__container {
    display: flex;
    align-items: center;
    height: 2.3vw;
    padding: 0 1vw;
    box-shadow: 0px 0px 0.12vw 0px #000000;
    border-radius: 0.4vw;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    /* max-width: 25%; */
}

.presentation__container span{
    font-size: 1vw;
}


.main__block__style {
    /* border: 2px blue solid; */
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.present__and__content__style {
    margin: 2vw 0;
    display: grid;
    gap: 15px;
}

.present__and__content__style h3 {
    font-size: 1vw;
    font-family: "Roboto";
    text-align: center;
    font-weight: 500;
}

.exit__button__style {
    /* border: 2px red solid; */
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}

.list__style span {
    text-align: center;
}

.drop__down__style span {
    font-family: "Roboto";
    color: rgb(138, 138, 138);
}

@media (max-width: 770px) {
    .main__block__style {
        display: block;
    }
    .drop__down__style {
        width: 100%;
        height: 11vw;
        box-shadow: 0px 0px 2vw -0.7vw #00000040;
        border: solid 0.2vw #bbbbbb;
        border-radius: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 2vw;
        /* border: 2px green solid; */
        margin: 0;
    }

    .first__block__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 1;
        padding: 0;
        margin: 0;
        width: 100%;
        gap: 15px;
    }
    .qr__style {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 2;
        margin: 0;
    }
    .qr__style span {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
        margin-bottom: 2vw;
    }
    .second__block__style {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        order: 3;
        /* border: orange 2px solid; */
        /* margin-bottom: 4vw; */
    }
    .description__block__style {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* border: 2px red solid; */
        order: 1;
    }
    .list__participants__style {
        align-items: center;
        /* border: violet 2px solid; */
        justify-content: start;
        width: 100%;
        order: 2;
        /* margin-top: 5vw; */
    }
    .second__block__style span {
        font-size: 3.5vw;
        font-family: "Roboto";
        font-weight: 500;
    }
    .second__block__style h3 {
        font-size: 4vw;
        font-family: "Roboto";
        font-weight: 500;
    }
    .list__style {
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        width: 100%;
        display: grid;
        gap: 2vw 0vw;
        padding: 3vw 0vw;
        border-radius: 4vw;
    }
    .list__style span {
        margin: 0 3vw;
    }
    .description__for__team {
        border-radius: 4vw;
        padding: 0;
        box-shadow: 0px 0px 3vw -1vw #00000040;
        border: 0.9vw solid var(--main-color);
        width: 100%;
        max-height: 50vw;
    }
    .for__padding__style {
        padding: 3vw;
    }
    .git__user__style {
        /* border: 2px red solid; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1vw 0 6vw 0;
        order: 2;
        width: 100%;
    }
    .present__and__content__style {
        /* margin: 2vw 0; */
        order: 2;
        /* border: 2px red solid; */
        /* margin-bottom: 6vw; */
    }
    .present__and__content__style h3 {
        font-size: 4vw;
    }
    .git__user__style__more {
        order: 0;
        margin-bottom: 1vw;
    }
    .exit__button__style {
        /* border: 2px red solid; */
        display: flex;
        justify-content: center;
        order: 4;
        /* margin-top: 5vw; */
    }

    .drop__down__style span {
        font-size: 3.5vw;
    }
}
